import React, { useEffect, useState } from "react";
import UserService from "../services/user.service";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../actions/auth";
import Web3 from "web3";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
let web3 = new Web3();

const Request = ({ address }) => {
  const [wallAddress, setWallAddress] = useState(address || '');
  const [isValid, setIsValid] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [required, setRequired] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddressChange = () => {
    if (!web3.utils.isAddress(wallAddress)) {
      console.log("----------12");
      setIsValid(false);
    } else {
      console.log("----------16");
      setIsValid(true);
    }
  };

  const requiredytuty = (value) => {
    if (!value) {
      return (
        <p className="text-danger" role="alert">
          This field is required!
        </p>
      );
    }
  };

  const handleSubmit = () => {
    if(wallAddress){
      setIsFailed(false);
      setIsComplete(false);
      UserService.changePurchaseStatus(currentUser?.data?._id, wallAddress).then(
        (res) => {
          if (res.status === 203) {
            setIsFailed(true);
          } else if (res.status === 200) {
            setIsComplete(true);
            dispatch(setAuth());
          }
        }
      );
    }else{
      setRequired(true);
    }
   
  };

  useEffect(() => {
    if (currentUser?.data.KYCStatus === 'Not Requested' || currentUser?.data.KYCStatus === 'Waiting Verification' || currentUser?.data?.KYCStatus === 'Declined'  ) {
      navigate("/profile/authentication");
    }
    if(wallAddress.length > 0) {
      handleAddressChange();
    }
  }, [wallAddress]);

  return (
    <>
      {!isComplete && !isFailed && (
        <div className="request-width w-50 mx-auto">
          <input
            type="text"
            className="form-control mt-3 request-input mb-4"
            placeholder="Enter your wallet address"
            value={wallAddress}
            onChange={(e) => {
              setWallAddress(e.target.value);
            }}
          />{required && (
            <p className="text-danger" role="alert">
          This field is required!
        </p>
          )}
          <div className="text-end">
            <button
              className="btn btn-primary"
              onClick={() => handleSubmit()}
              disabled={!isValid}
            >
              Request Token Transfer
            </button>
            {!isValid && (
              <p className="text-danger">Please Enter Valid Wallet Address</p>
            )}
          </div>
        </div>
      )}
      {(isComplete || isFailed) && (
        <div className="card card-container mt-0">
          {isComplete && (
            <>
              <h4 className="text-center text-success mb-4">
                Token Requested Sucessfully
              </h4>
              <div className="alert alert-success mb-4">
                Your request has been received successfully. Please wait for it
                to complete
              </div>
              <Link
                to="/profile"
                className="d-flex align-items-center justify-content-center"
                onClick={() => {
                  setIsFailed(false);
                  setIsComplete(false);
                }}
              >
                View My Profile
              </Link>
            </>
          )}
          {isFailed && (
            <>
              <h4 className="text-center text-danger mb-4">Request Failed</h4>
              <div className="alert alert-danger mb-4">
                Could not process the request due to insufficient tokens. Please
                try later!
              </div>
              <a
                href="#"
                className="d-flex align-items-center justify-content-center"
                onClick={() => {
                  setIsFailed(false);
                  setIsComplete(false);
                }}
              >
                <i className="fa-solid fa-arrow-left"></i>Back
              </a>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Request
