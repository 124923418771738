import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  FORGOT_SUCCESS,
  FORGOT_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESEND_MAIL_SUCCESS,
  RESEND_MAIL_FAIL,

} from "./type";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

export const register = (firstName, lastName, email, password,referralCode,agree) => (dispatch) => {
  return AuthService.register(firstName, lastName, email, password,referralCode,agree).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      console.log(error);
      dispatch({
        type: LOGIN_FAIL,
        // payload: error.response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {error: error.response.data.error},
      });

      return Promise.reject();
    }
  );
};

export const setAuth =  () => (dispatch) => {
  const auth_user = JSON.parse(localStorage.getItem("user"));
  dispatch({
    type: LOGIN_SUCCESS,
    payload: { user: auth_user },
  }); 
}

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const userForgotPassword = (email) => (dispatch) => {
  return UserService.userForgotPassword(email).then(
    (data) => {
      dispatch({
        type: FORGOT_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      console.log(error);
      dispatch({
        type: FORGOT_FAIL,
        payload: error
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {error: error},
      });

      return Promise.reject();
    }
  );
};


export const userResetPassword = (token, password) => (dispatch) => {
  return UserService.userResetPassword(token, password).then(
    (data) => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      console.log(error);
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: error
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {error: error},
      });

      return Promise.reject();
    }
  );
};

export const userResendVerifyMail = (email) => (dispatch) => {
  return UserService.userResendVerifyMail(email).then(
    (data) => {
      dispatch({
        type: RESEND_MAIL_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      console.log(error);
      dispatch({
        type: RESEND_MAIL_FAIL,
        payload: error
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {error: error},
      });

      return Promise.reject();
    }
  );
};


