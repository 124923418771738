import {
    CREDIT_PAYMENT_SUCCESS,
    CREDIT_PAYMENT_FAIL,
    STRIPE_PAYMENT_FAIL,
    STRIPE_PAYMENT_SUCCESS,
    SET_MESSAGE,
  } from "./type";
  
  import StripeService from "../services/stripe.service";
  
  export const creditCardPayment = (items, purchaseType) => (dispatch) => {
    return StripeService.creditCardPayment(items, purchaseType).then(
      (data) => {
        dispatch({
          type: CREDIT_PAYMENT_SUCCESS,
          payload: { stripeToken: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        dispatch({
          type: CREDIT_PAYMENT_FAIL,
          // payload: error.response.data
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: {error: error.response},
        });
  
        return Promise.reject();
      }
    );
  };

  export const saveStripePurchaseRecord = (stripeId,paymentStatus,purchaseType,amountUSD,totalAmountUSD,feeUSD,creditCardFeeUSD,paymentIntent_id) => (dispatch) => {
    return StripeService.saveStripePurchaseRecord(stripeId,paymentStatus,purchaseType,amountUSD,totalAmountUSD,feeUSD,creditCardFeeUSD,paymentIntent_id).then(
      (data) => {
        dispatch({
          type: STRIPE_PAYMENT_SUCCESS,
          payload: { stripeData: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        dispatch({
          type: STRIPE_PAYMENT_FAIL,
          // payload: error.response.data
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: {error: error.response},
        });
  
        return Promise.reject();
      }
    );
  };
  
