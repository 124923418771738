import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;


  const creditCardPayment = (items,purchaseType) => {
    return axios
      .post(API_URL + "/user/addPurchaseRecord", {
        items,
        purchaseType
      }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  };

  const saveStripePurchaseRecord = (stripeId,paymentStatus,purchaseType,amountUSD,totalAmountUSD,feeUSD,creditCardFeeUSD,paymentIntent_id) => {
    return axios
      .post(API_URL + "/user/save/stripePurchaseRecord", {
        stripeId,
        paymentStatus,
        purchaseType,
        amountUSD,
        totalAmountUSD,
        feeUSD,
        creditCardFeeUSD,
        paymentIntent_id,
      }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  };


const StripeService = {
    creditCardPayment,
    saveStripePurchaseRecord,
}

export default StripeService;