import {
    CREDIT_PAYMENT_FAIL,
    CREDIT_PAYMENT_SUCCESS,
    STRIPE_PAYMENT_FAIL,
    STRIPE_PAYMENT_SUCCESS
  } from "../actions/type";
  
  const initialState = {data: null}
  
  export default function stripe(state = initialState, action) {
    const { type } = action;
    switch (type) {
      case CREDIT_PAYMENT_SUCCESS:
        return {
            ...state,
            isLoggedIn: true,
            stripeToken: action.payload,
        };
      case CREDIT_PAYMENT_FAIL:
        return {
          data: {
            ...state,
            isLoggedIn: true,
            user: action.payload,
          }
        }
        case STRIPE_PAYMENT_SUCCESS:
        return {
            ...state,
            isLoggedIn: true,
            stripeToken: action.payload,
        };
      case STRIPE_PAYMENT_FAIL:
        return {
          data: {
            ...state,
            isLoggedIn: true,
            user: action.payload,
          }
        }
      default:
        return state;
    }
  }