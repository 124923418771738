import axios from "axios";
import WalletConnect from "@walletconnect/web3-provider";
import Web3 from "web3";
import Swal from "sweetalert2";
import {ETH_ADDRESS,BNB_ADDRESS,MORALIS_API_KEY} from "../constants/constants";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL;
let web3;


const getPublicContent = () => {
  return axios.get(API_URL + "/all");
};

// const switchNetwork = async (
//   chainId,
//   name,
//   rpc,
//   native_name,
//   native_symbol,
//   decimals,
//   explorer,
//   connector
// ) => {
//   try {
//     if (localStorage.getItem("walletconnect")) {
//       web3 = new Web3(connector);
//     } else {
//       web3 = new Web3(window.ethereum);
//     }
//     await web3.currentProvider.request({
//       method: "wallet_switchEthereumChain",
//       params: [{ chainId: chainId }],
//     });
//   } catch (error) {
//     if (error.code === 4902) {
//       try {
//         await web3.currentProvider.request({
//           method: "wallet_addEthereumChain",
//           params: [
//             {
//               chainId: chainId,
//               chainName: name,
//               rpcUrls: [rpc],
//               nativeCurrency: {
//                 name: native_name,
//                 symbol: native_symbol,
//                 decimals: decimals,
//               },
//               blockExplorerUrls: [explorer],
//             },
//           ],
//         });
//       } catch (error) {
//         Swal.fire({
//           icon: "error",
//           title: "error",
//           showConfirmButton: false,
//           text: error.message,
//         });
//         throw error;
//       }
//     } else {
//       throw error;
//     }
//   }
// };

// const sendTransition = async (adminAddress, value, userAddress, chain,connector) => {
//   let b =  web3.utils.toWei(value,'ether')
//   b= web3.utils.toHex(b);
  
//   if (localStorage.getItem("walletconnect")) {
//     const web3_instance = new Web3(connector);
//     return await web3_instance.eth
//     .sendTransaction({
//         from: userAddress,
//         to: adminAddress,
//         value: b,
//       })
//       .then((result) => result)
//       .catch((error) => {
//         Swal.fire({
//           icon: "error",
//           title: "Transaction Failed",
//           text: "Transaction failed due to unexpected error!",
//           showCloseButton: false,
//         })
//         throw error;
//       });
//   } else {
//     const transactionParameters = {
//       nonce: "0x00", // ignored by MetaMask
//       gasPrice: "0x09184e72a000", // customizable by user during MetaMask confirmation.
//       gas: "0x2710", // customizable by user during MetaMask confirmation.
//       to: adminAddress, // Required except during contract publications.
//       from: userAddress, // must match user's active address.
//       value: b, // Only required to send ether to the recipient from the initiating external account.
//       chainId: chain, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
//     };

//     // txHash is a hex string
//     // As with any RPC call, it may throw an error
//     return await window.ethereum
//       .request({
//         method: "eth_sendTransaction",
//         params: [transactionParameters],
//       })
//       .then((response) => response)
//       .catch((err) => {
//         Swal.fire({
//           icon: "error",
//           title: "Transaction Failed",
//           text: "Transaction failed due to unexpected error!",
//           showCloseButton: false,
//         })
//         throw err;
//       });
//   }
// };

// const sendTestTransition = async (adminAddress, value, userAddress, chain,connector) => {
//   let b =  web3.utils.toWei(value,'ether')
//   b= web3.utils.toHex(b);
//   let web3_instance1 = new Web3(window.ethereum);
//   if (localStorage.getItem("walletconnect")) {
//     const web3_instance2 = new Web3(connector);
//     return await web3_instance2.eth
//     .sendTransaction({
//         from: userAddress,
//         to: adminAddress,
//         value: b,
//       })
//       .then((result) => result)
//       .catch((error) => {
//         Swal.fire({
//           icon: "error",
//           title: "Transaction Failed",
//           text: "Transaction failed due to unexpected error!",
//           showCloseButton: false,
//         })
//         throw error;
//       });
//   } else {
//     return await web3_instance1.eth
//     .sendTransaction({
//         from: userAddress,
//         to: adminAddress,
//         value: b,
//       })
//       .then((result) => {
//         return result;
//       })
//       .catch((error) => {
//         Swal.fire({
//           icon: "error",
//           title: "Transaction Failed",
//           text: "Transaction failed due to unexpected error!",
//           showCloseButton: false,
//         })
//         throw error;
//       });
//   }
// };

const getPrice = async (chain) => {
  return fetch(`https://deep-index.moralis.io/api/v2/erc20/${chain == '0x1'?ETH_ADDRESS:BNB_ADDRESS}/price?chain=${chain}`, {
    headers: {accept: 'application/json','X-API-Key': MORALIS_API_KEY}
  })
  .then(response => response.json())
  .then(response => response)
  .catch(err => {throw err});
}

const saveTransaction = async (totalAmountUSD,amountUSD,feeUSD,purchaseType,txHash) =>{
  return axios.post(API_URL + "/user/addPurchaseRecord", {
    amountUSD: amountUSD,
    feeUSD:feeUSD,
    totalAmountUSD:totalAmountUSD,
    purchaseType: purchaseType,
    txHash: txHash.transactionHash,
    isSuccessful : txHash.status === 1 ? true : false,
    purchaseFromWallet : txHash.from,
  },{ headers: authHeader()});
}

const saveReward = async (amountUSD,id) =>{
  return axios.post(API_URL + "/user/addRewardRecord", {
    amountUSD,id
  },{ headers: authHeader()});
}

const getPurchases = async(usedId) => {
  return axios
    .get(API_URL + `/user/purchases/get/all?id=${usedId}`, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};

const changePurchaseStatus = async(userId,address) => {
  return axios
    .post(API_URL + `/user/purchases/update-purchase-status`,{userId,address},{ headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const upload = async(formdata) => {
  return axios.post(API_URL + "/user/upload", formdata,{ headers: authHeader() }).then((res) => res);
};

const userForgotPassword = async(email) => {
  return axios
    .post(API_URL + `/user/forgotPassword`,{email})
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error.response.data;
    });
};

const userResetPassword = async(token,password) => {
  return axios
    .post(API_URL + `/user/resetPassword/${token}`,{password})
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error.response.data;
    });
};

const userResendVerifyMail = async(email) => {
  return axios
    .post(API_URL + `/user/resendVerifyMail`,{email},{ headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error.response.data;
    });
};


const submitKYC = (front,back,side) => {
  return axios.post(API_URL + "/user/saveImages", {front,back,side},{ headers: authHeader() }).then((res) => res);
};

const getNotes = (id)=>{
  return axios.post(API_URL + "/user/notes/getUserNotes",{id},{ headers: authHeader() }).then((res) => res);
};

const saveProfile = (firstName, lastName, phoneNumber,referredBy)=>{
  return axios.post(API_URL + "/user/profile/edit",{firstName, lastName, phoneNumber,referredBy},{ headers: authHeader() }).then((res) => res);
};

const getReferralUsedBy = () => {
  return axios.get(API_URL + "/user/referrals",{ headers: authHeader() }).then((res) => res);
}


const UserService = {
  getPublicContent,
  // switchNetwork,
  // sendTransition,
  // sendTestTransition,
  saveReward,
  getPrice,
  getReferralUsedBy,
  saveTransaction,
  getPurchases,
  changePurchaseStatus,
  upload,
  userForgotPassword,
  userResetPassword,
  userResendVerifyMail,
  submitKYC,
  getNotes,
  saveProfile,
}

export default UserService;