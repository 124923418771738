import { useEffect, useCallback } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../actions/auth";
import { logout } from "../actions/auth";
import UserService from "../services/user.service";
import Swal from "sweetalert2";
import { useDisconnect } from "wagmi";

const KYC = () => {
	const { user: currentUser } = useSelector((state) => state.auth);
	const [front, setFront] = useState(currentUser?.data?.kyc?.front || "");
	const [back, setBack] = useState(currentUser?.data?.kyc?.back || "");
	const [side, setSide] = useState(currentUser?.data?.kyc?.side || "");
	const [isSaved, setIsSaved] = useState(true);

	const dispatch = useDispatch();

	const { disconnect } = useDisconnect({
		onSuccess(data) {
		  console.log("Success", data);
		},
	  });

	const logOut = useCallback(() => {
		disconnect();
		dispatch(logout());
	  }, [dispatch]);

	const onProfileImageChange = (e, set) => {
		let file = e.target.files[0];
		let formData = new FormData();
		formData.append("file", file);
		UserService.upload(formData).then((res) => {
			setIsSaved(false);
			set(res.data.url);
		});
	};

	const onSubmit = () => {
		UserService.submitKYC(front, back, side).then((res) => {
			let user = JSON.parse(window.localStorage.getItem("user"));
			user.data.kyc = {
				back,
				front,
				side,
			};
			user.data.KYCStatus = 'Waiting Verification'
			window.localStorage.setItem("user", JSON.stringify(user));
			dispatch(setAuth());
			setIsSaved(true);
		});
	};
	
	return (
		<>
		{currentUser?.data?.KYCStatus === 'Declined' &&
		
		<div><h4 className="text-danger m-0">Your KYC documents have been declinedl</h4>{currentUser?.data?.comments && <h4 className="text-danger m-0">Message : {currentUser?.data?.comments}</h4>}</div>
		}
		{currentUser?.data?.KYCStatus === 'Waiting Verification' && (
			<h4 className="text-white m-0">Your documents have been sent for KYC approval. Please logout and return when you receive an email that your documents have been approved.</h4>
		)}
		{currentUser?.data?.KYCStatus === 'Not Requested' && (
			<h2 className="text-white">Please upload your documents for KYC</h2>
		)}
			{/* {currentUser?.data?.KYCStatus === 'Waiting Verification' && (
				<h4 className="text-success m-0">Message: Please wait while admin verifies your uploaded documents!</h4>
			)}
			{currentUser?.data?.KYCStatus === 'Declined' && <h4 className="text-danger m-0">Message: {currentUser?.data?.comments}</h4>} */}
			<div className="kycInputs mt-5">
				<div className="inputMain">
					{front !== "" ? (
						<div className="preview-image text-center">
							{currentUser?.data?.KYCStatus !== 'Waiting Verification' && (
							<div className="crossIcon">
								<i className="fa-solid text-white fa-circle-xmark" onClick={() => setFront("")}></i>
							</div>
							)}
							{front.split(".").pop() === "pdf" ? <span className="text-white">{front}</span>:
							<img src={front} alt="" />}
						</div>
					) : (
						<div>
							<input type="file" onChange={(e) => onProfileImageChange(e, setFront, 1)} />
							<h2 className="text-secondary">Front Image of ID</h2>
							<br />
						</div>
					)}
				</div>
				<div className="inputMain">
					{back !== "" ? (
						<div className="preview-image text-center">
							{currentUser?.data?.KYCStatus !== 'Waiting Verification' && (
							<div className="crossIcon">
								<i className="fa-solid text-white fa-circle-xmark" onClick={() => setBack("")}></i>
							</div>
							)}
							{back.split(".").pop() === "pdf" ? <span className="text-white">{back}</span>:
							<img src={back} alt="" />}
						</div>
					) : (
						<div>
							<input type="file" onChange={(e) => onProfileImageChange(e, setBack, 2)} />
							<h2 className="text-secondary">Back Image of ID</h2>
							<br />
						</div>
					)}
				</div>

				<div className="inputMain">
					{side !== "" ? (
						<div className="preview-image text-center">
							{currentUser?.data?.KYCStatus !== 'Waiting Verification' && (
							<div className="crossIcon">
								<i className="fa-solid text-white fa-circle-xmark" onClick={() => setSide("")}></i>
							</div>
							)}
							{side.split(".").pop() === "pdf" ? <span className="text-white">{back}</span>:
							<img src={side} alt="" />}
						</div>
					) : (
						<div>
							<input type="file" onChange={(e) => onProfileImageChange(e, setSide, 3)} />
							<h2 className="text-secondary">Selfie with picture of ID</h2>
							<br />
						</div>
					)}
				</div>
			</div>
			<div className="row justify-content-end py-4">
				<div className="col-3">
					<div className="text-end">
						<button
							className="btn btn-primary w-100"
							onClick={() => onSubmit()}
							disabled={front === "" || back === "" || side === "" || isSaved}>
							{isSaved ? "Saved" : "Submit"}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default KYC;
