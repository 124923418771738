import React, {useEffect,useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate,useNavigate,Routes, Route, Link, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import WalletConnectProvider  from "@walletconnect/web3-provider";
import Web3 from "web3";
import Swal from "sweetalert2";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import metamask from "./assets/metamask.svg";
import walletConnectLogo from "./assets/walletConnect.svg";
import logo from "./assets/logo.png";
import UserService from "../src/services/user.service";

import ResendLink from "./components/ResendLink";
import Login from "./components/Login";
import Register from "./components/Register";
import Purchase from "./components/Purchase";
import Profile from "./components/Profile";
import KYC from "./components/KYC";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import { tokenConfig } from "./actions/admin";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Request from "./components/Request";
import AchPayment from "./components/AchPayment";
import Completion from "./components/Completion";
import { useWeb3Modal, Web3Button } from "@web3modal/react";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";

let web3 = new Web3(Web3.givenProvider || "https://mainnet.infura.io/v3/" || "https://bsc-dataseed1.binance.org/");

const App = () => {
  const [byobs, setByobs] = useState(0);
  const [usd, setUSD] = useState(0);
  
  const { user: currentUser } = useSelector((state) => state.auth);
  
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  let a = window.location.pathname;
  let b = a.slice(1);

  const { disconnect } = useDisconnect({
    onSuccess(data) {
      console.log("Success", data);
    },
  });
  
  const {signMessage } = useSignMessage({
    message: 'Connecting to KindomCurrency App Panel.',
    onError(error) {
      console.log('Error', error)
      disconnect()
    },
  })

  const {address} = useAccount({
    onConnect({ address, connector, isReconnected }) {
      if(isReconnected){
        console.log('Reconnected',address)
      }else{
        signMessage();
      }
    },
  });


  const capitalizeFirstLetter = (text) => {
    if(text){
      return text[0].toUpperCase() + text.substr(1).toLowerCase();
    }
  };

  const calculateTokens = async () => {
    var sum = 0,
      usdPrice = 0;
    UserService.getPurchases(currentUser?.data?._id)
      .then((response) =>
        response.data.data.filter((item) => item.transferStatus === "not requested" && item.isSuccessful === true)
      )
      .then((res) => {
        for (let i = 0; i < res.length; i++) {
          sum = sum + res[i].byobAmount;
          usdPrice = usdPrice + res[i]?.amountUSD;
        }
        setUSD(usdPrice);
        setByobs(sum);
      });
  };

  const fixDecimals = (item) => {
    if(item){
      return item.toFixed(6);
    }
  }


  const logOut = useCallback(() => {
    disconnect();
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (["/", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);
 
 

  useEffect(() => {
    if (currentUser) {
     dispatch(tokenConfig("BYOB"));
      calculateTokens();
    } else {
      
    }
  }, [currentUser]);

  const handleCollapse = () => {
    console.log("handleCollapse");
    var nav = document.getElementById("navbarNavDropdown");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };

  return (
    <Elements stripe={promise}>
      <div className="bg-black">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-5">
          <div className="container-fluid">
          <Link to={"/"} className="navbar-brand">
            <img src={logo} alt="logo" className="profile-img-card  img-fluid" />
          </Link>

          {currentUser && (
            <h4 className="text-start text-white">
              Welcome{" "}
              {capitalizeFirstLetter(currentUser?.data?.firstName) +
                " " +
                capitalizeFirstLetter(currentUser?.data?.lastName)}
            </h4>
          )}
             <button className="navbar-toggler" id="navbarBtn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
            {/* <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
             data-bs-auto-close="true"
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
            {!currentUser && <ul className="navbar-nav ms-auto">
               
               <li className="nav-item">
                 <a
                   href="http://byobkingdomcurrency.com/"
                   onClick={handleCollapse}
                   className={`nav-link tabs-link ${b === "/" ? "active" : ""}`}
                 >
                   Home
                 </a>
               </li> 
               <li className="nav-item">
                  <Link
                    to={"/purchase"}
                    onClick={handleCollapse}
                    className={`nav-link tabs-link ${
                      b === "" ? "active" : ""
                    }`}
                  >
                    Purchase BYOB
                  </Link>
                </li>
                {/* <li className="nav-item">
                 <a
                   href="http://byobkingdomcurrency.com/about-us"
                   onClick={handleCollapse}
                   className={`nav-link tabs-link ${b === "about-us" ? "active" : ""}`}
                 >
                   About Us
                 </a>
               </li>  */}
               <li className="nav-item">
                 <a
                   href="http://byobkingdomcurrency.com/#faq"
                   onClick={handleCollapse}
                   className={`nav-link tabs-link ${b === "faq" ? "active" : ""}`}
                 >
                   FAQ
                 </a>
               </li> 
               <li className="nav-item">
                 <a
                   href="http://byobkingdomcurrency.com/#contact"
                   onClick={handleCollapse}
                   className={`nav-link tabs-link ${b === "contact" ? "active" : ""}`}
                 >
                   Contact
                 </a>
               </li>
             </ul>}
              {currentUser && <ul className="navbar-nav ms-auto">
               
                <li className="nav-item">
                  <a
                    href="http://byobkingdomcurrency.com/"
                    onClick={handleCollapse}
                    className={`nav-link tabs-link ${b === "" ? "active" : ""}`}
                  >
                    Home
                  </a>
                </li>
              
                <li className="nav-item">
                  <Link
                    to={"/purchase"}
                    onClick={handleCollapse}
                    className={`nav-link tabs-link ${
                      b === "purchase" ? "active" : ""
                    }`}
                  >
                    Purchase Tokens
                  </Link>
                </li>
              
                {usd && (
                <li className="nav-item">
                  <Link
                    to={"/request-token-transfer"}
                    onClick={handleCollapse}
                    className={`nav-link tabs-link ${
                      b === "request-token-transfer" ? "active" : ""
                    }`}
                  >
                    Request Tokens
                  </Link>
                </li>
                )}        
               
                <li className="nav-item">
                  <Link
                    to={"/profile"}
                    onClick={handleCollapse}
                    className={`nav-link tabs-link ${
                      b === "profile" ? "active" : ""
                    }`}
                  >
                    My Profile
                  </Link>
                </li>
                {/* <li className="nav-item">
                 <a
                   href="http://byobkingdomcurrency.com/about-us"
                   onClick={handleCollapse}
                   className={`nav-link tabs-link ${b === "about-us" ? "active" : ""}`}
                 >
                   About Us
                 </a>
               </li>  */}
               <li className="nav-item">
                 <a
                   href="https://www.byobkingdomcurrency.com/#faq"
                   onClick={handleCollapse}
                   className={`nav-link tabs-link ${b === "faq" ? "active" : ""}`}
                 >
                   FAQ
                 </a>
               </li> 
               <li className="nav-item">
                 <a
                   href="http://www.byobkingdomcurrency.com/#contact"
                   onClick={handleCollapse}
                   className={`nav-link tabs-link ${b === "contact" ? "active" : ""}`}
                 >
                   Contact
                 </a>
               </li>
              
              
                <li className="nav-item ">
                  <a href="/" className="nav-link" onClick={logOut}>
                    LogOut
                  </a>
                </li>
              
               
                <Web3Button label="Connect Wallet" icon="hide" />
              
                
              </ul>}
            </div>
          </div>
        </nav>
        {currentUser && (
          <div className="container">
            {byobs > 0 && (
              <div className="pt-4">
                <p className="current-text mb-0">
                  You currently have {fixDecimals(byobs)} BYOB Tokens (${usd}{" "}
                  USD) to transfer to your wallet.
                </p>
                <Link
                  to="/request-token-transfer"
                  className={`btn button-lime button-lime-hover text-white`}
                >
                  {/* Transfer {fixDecimals(byobs)} Tokens to Your Wallet */}
                  Transfer Token
                </Link>
              </div>
            )}
          </div>
        )}

        <div className="container pt-4">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resendLink" element={<ResendLink />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route
              path="/resetPassword/:resetToken"
              element={<ResetPassword />}
            />
            <Route path="/purchase" element={<Purchase address={address} />} />
            <Route
              path="/request-token-transfer"
              element={<Request address={address} />}
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/authentication" element={<KYC />} />
            <Route path="/ach" element={<AchPayment />} />
            <Route path="/completion" element={<Completion />} />
          </Routes>
        </div>
      </div>
      <footer id="site-footer" class="container">
        <div class="footer-inner">
                <div class="copyright float-end">
              <p class="text-white">BYOB Kingdom Currency (BYOB2812), All Rights Reserved</p>
            </div>
            </div>
      </footer>
    </Elements>
  );
};

export default App;
