import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;



const register = (firstName, lastName, email, password,referralCode,agree) => {
    return axios.post(API_URL + "/user/signup", {
      firstName,
      lastName,
      email,
      password,
      referralCode,
      agree
    });
  };

  const login = (email, password) => {
    return axios
      .post(API_URL + "/user/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.data.email) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  };
  
  const logout = () => {
    localStorage.removeItem("user");
    // return axios.post(API_URL + "/user/signout").then((response) => {
    //   return response.data;
    // });
  };
  
  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
}

export default AuthService;