import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import admin from "./admin";
import stripe from "./stripe";

export default combineReducers({
  auth,
  message,
  admin,
  stripe,
});