import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { userResetPassword } from "../actions/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isStrongPassword } from "validator";
import {
  useParams, useNavigate
} from "react-router-dom";

const ResetPassword = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const resetPass = useSelector((state) => state.auth.resetPass);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { resetToken } = useParams();

  console.log(resetPass,"---------resetPass------");
  let new_pass = "";
  let confirm_pass = "";
  const onChange = (value,e) => {
      if(e.name === "newPassword"){
        if (!isStrongPassword(e.value, {
          minLength: 6, minLowercase: 1,
          minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
          return (
            <p className="text-danger" role="alert">
          The password must be 6 characters and also contain the uppercase letter, lowercase letter, number and special character.
        </p>
          );
        }
        new_pass = e.value; 
      }
      if(e.name === "confirmPassword"){
        confirm_pass = e.value; 
      } 
      if(e.name === "confirmPassword"){
      if (confirm_pass !== new_pass) {
        return (
          <p className="text-danger" role="alert">
             The password must be match with new password.
          </p>
        );
      }}
    };

  const onChangeNewPassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
  };
  
  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
   };
  
  const required = (value) => {
    if (!value) {
      return (
        <p className="text-danger" role="alert">
          This field is required!
        </p>
      );
    }
  };

  useEffect(() => {
    if(resetPass?.status === "success"){
      toast.success(resetPass?.msg, {
        onClose: () =>  navigate("/") });  
    }else{
      toast.error(resetPass?.msg, {
        onClose: () =>  navigate("/") });
    }
  }, [resetPass]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        userResetPassword(resetToken,newPassword)
      );
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <h4 className="text-center">
          <strong>Reset Password</strong>
        </h4>
        <Form onSubmit={handleSubmit} ref={form}>
          <div className="form-group">
            <Input
              placeholder="New Passsword"
              type="password"
              className="form-control mt-3"
              name="newPassword"
              value={newPassword}
              onChange={onChangeNewPassword}
              validations={[onChange,required]}
            />
          </div>

          <div className="form-group">
            <Input
              placeholder="Confirm Passsword"
              type="password"
              className="form-control mt-3"
              name="confirmPassword"
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              validations={[onChange,required]}
            />
          </div>
          <div className="form-group mt-2 text-center d-grid gap-2 mt-4">
            <button className="btn button-lime button-lime-hover btn-block btn-lg">
              <span>Reset Password</span>
            </button>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default ResetPassword;
