const NumberOnly = (e) => {
    const key = window.event ? e.which : e.keyCode;
	if (key < 48 || key > 57) {
		e.preventDefault();
	}
}

const Directives = {
    NumberOnly
}

export default Directives;