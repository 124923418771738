import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isStrongPassword } from "validator";

import { register } from "../actions/auth";
import { useLocation } from "react-router-dom";

var referralCode;

const required = (value) => {
  if (!value) {
    return (
      <p className="text-danger" role="alert">
        This field is required!
      </p>
    );
  }
};

const vagree = (value) => {
  if (value === 'false') {
    return (
      <p className="text-danger" role="alert">
        You must accept our terms and conditions!
      </p>
    );
  }
};


const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <p className="text-danger" role="alert">
        This is not a valid email.
      </p>
    );
  }
};

const vfirstName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <p className="text-danger" role="alert">
        The first name must be between 3 and 20 characters.
      </p>
    );
  }
};

const vlastName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <p className="text-danger" role="alert">
        The last name must be between 3 and 20 characters.
      </p>
    );
  }
};


const Register = () => {
  const form = useRef();
  const checkBtn = useRef();
  function useQuery() {
    const { search } = useLocation();

    let query = React.useMemo(() => new URLSearchParams(search), [search]);
    referralCode = query.get("referralCode");
  }

  useQuery();
  console.log(referralCode)

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [agree, setAgree] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const checkboxHandler = () => {
    setAgree(!agree);
  }

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };

  var v_password = "";
var v_confirmPassword = "";

const vpassword = (value,e) => {
  if(e.name === "password"){

    if(!isStrongPassword(e.value, {
      minLength: 6, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
      return (
        <p className="text-danger" role="alert">
          The password must be 6 characters and also contain the uppercase letter, lowercase letter, number and special character.
        </p>
      );
    } 
    // if (e.value.length < 6 || e.value.length > 40) {
    //   return (
    //     <p className="text-danger" role="alert">
    //       The password must be 6 characters and also contain the uppercase letter, lowercase letter, number and special character.
    //     </p>
    //   );
    // }
    v_password = e.value;

  }
  if(e.name === "confirmPassword"){
    v_confirmPassword = e.value;
  }

  if(e.name === "confirmPassword"){
    if (v_confirmPassword != v_password) {
      return (
        <p className="text-danger" role="alert">
          The password must be match with new password.
        </p>
      );
    }
  }
 
};

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        register(firstName, lastName, email, password, referralCode, agree)
      )
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container mt-0">
        {!successful && <h2 className="text-center color-lime">Register</h2>}
        {successful && (
          <h4 className="text-center color-lime mb-4">Register successfully</h4>
        )}

        {message && (
          <div className="form-group">
            <div
              className={
                successful ? "alert alert-success" : "alert alert-danger"
              }
              role="alert"
            >
              {message}
            </div>
          </div>
        )}
        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="firstname">First Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="firstname"
                  value={firstName}
                  onChange={onChangeFirstName}
                  validations={[required, vfirstName]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastname">Last Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="lastname"
                  value={lastName}
                  onChange={onChangeLastName}
                  validations={[required, vlastName]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Confirm Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChangeConfirmPassword}
                  validations={[required, vpassword]}
                />
              </div>
              <div className="form-check mt-3 agree">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  onChange={checkboxHandler}
                  value={agree}
                  id="agree"
                  validations={[required, vagree]}
                />
                <label
                  className="form-check-label mt-0"
                  htmlFor="flexCheckDefault"
                >
                  I have read and agree to the <a href={"http://byobkingdomcurrency.com/terms"} className="text-decoration-none">
                terms and conditions
              </a>
                </label>
              </div>

              <div className="form-group  mt-4 text-center d-grid gap-2 mb-4">
                <button className="btn button-lime button-lime-hover btn-block">
                  Sign Up
                </button>
              </div>
            </div>
          )}
          
          <div className="text-center">
            <p>
              Have an account{" "}
              <a href={"/"} className="text-decoration-none">
                Log in
              </a>
            </p>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
