import React, { useEffect, useState } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Directives from "../directives";
import UserService from "../services/user.service";
import AdminService from "../services/admin.service";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../components/stripe/stripe.css";
import { setAuth } from "../actions/auth";
import {
  usePrepareSendTransaction,
  useSendTransaction,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { parseEther } from "ethers/lib/utils.js";
import { saveStripePurchaseRecord } from "../actions/stripe";
/* global BigInt */

const Purchase = ({ address }) => {
  const { isOpen, open, close } = useWeb3Modal();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { stripeToken } = useSelector((state) => state.stripe);
  console.log(stripeToken,"---------------");

  const admin = useSelector((state) => state.admin);
  const [amount, setAmount] = useState("");
  const [limitError, setLimitError] = useState("");
  const [data, setData] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [usdPrice, setUsdPrice] = useState(1);
  const [isChanged, setIsChanged] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  //credit card payment
  // const [showModal, setShowModal] = useState(false);
  // const [succeeded, setSucceeded] = useState(false);
  // const [error, setError] = useState(null);
  // const [processing, setProcessing] = useState("");
  // const [disabled, setDisabled] = useState(true);
  // const [clientSecret, setClientSecret] = useState("");
  const [wait, setWait] = useState(false);
  const dispatch = useDispatch();

  let navigate = useNavigate();
  let currentChain,
    price = 1;

  let { state } = useLocation();

  const handleCreditCardModal = (payment_method) => {
    setPaymentType(payment_method);
    if (admin.data) {
      if (amount === "") {
        setLimitError(`Minimum amount should be ${admin.data.min} and less than ${admin.data.max}`);
      } else if (amount < admin.data.min) {
        setLimitError(`Minimum amount is ${admin.data.min}`);
      } else if (amount > admin.data.max) {
        setLimitError(`Maximum amount is ${admin.data.max}`);
      } else {
        let stripeAmount;
        if(payment_method === "card"){
          console.log((amount*0.06) + parseInt(amount) + admin?.data?.processingFee);
          stripeAmount = (amount*0.06) + parseInt(amount) + admin?.data?.processingFee;
        }else{
        stripeAmount = (parseInt(amount) + admin?.data?.processingFee);
        }
        navigate("/ach", { state: { user_amount: stripeAmount*100 , purchaseType: payment_method, processingFee: admin?.data?.processingFee} });
      }
    }
  };

  const { switchNetwork } = useSwitchNetwork({
    onSuccess(data) {
      UserService.getPrice(currentChain)
        .then((res) => {
          setUsdPrice(res.usdPrice);
          return res.usdPrice;
        })
        .then((res) => {
          console.log(res);
          sendToken();
        });
    },
  });

  const { config } = usePrepareSendTransaction({
    request: {
      to: currentItem?.companyWalletAddress
        ? currentItem?.companyWalletAddress
        : "0x0000000000000000000000000000000000000000",
      value: (Number(amount) + Number(admin?.data?.processingFee)) ? parseEther("0") : undefined,
    },
  });

  const { data: sendData,isLoading, sendTransaction,reset } = useSendTransaction({
    ...config,
    onError(data) {
      console.log(data);
      setWait(false);
      setIsChanged(false);
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Transaction has been Failed!",
      });
    },
  });

  const {
    data: txHash,
  } = useWaitForTransaction({
    hash: sendData?.hash,
    onSuccess(data) {
      setWait(false);
      if(isChanged){
        UserService.saveTransaction((Number(amount) + Number(admin?.data?.processingFee)),Number(amount),Number(admin?.data?.processingFee),currentItem?.tokenName,data).then(
          (response) => {
            if(currentUser.data?.referredBy !== ""){
              UserService.saveReward((Number(amount)) ,response?.data?.data?._id).then((res) => console.log("reward:",res))
            }
            setIsChanged(false);
            dispatch(setAuth());
            Swal.close();
            Swal.fire({
              confirmButtonColor: "#d3bd0e",
              icon: "success",
              title: "Transaction Complete",
              text: "Transaction Completed Sucessfully!",
              timer:2000,
              timerProgressBar:true,
              didOpen:()=>{
                Swal.showLoading();
              },
            }).then(() => {
              Swal.close();
              navigate("/request-token-transfer");
            });
          }
        );
      }
    },
    onError(data) {
      let txData = {
        transactionHash: sendData?.hash,
        from: address,
        status: 1
      }
      setTimeout(() => {
        setWait(false);
        if(isChanged){
          UserService.saveTransaction((Number(amount) + Number(admin?.data?.processingFee)),Number(amount),Number(admin?.data?.processingFee),currentItem?.tokenName,txData).then(
            (response) => {
              if(currentUser.data?.referredBy !== ""){
                UserService.saveReward((Number(amount)) ,response?.data?.data?._id).then((res) => console.log("reward:",res))
              }
              setIsChanged(false);
              dispatch(setAuth());
              Swal.close();
              Swal.fire({
                confirmButtonColor: "#d3bd0e",
                icon: "success",
                title: "Transaction Complete",
                text: "Transaction Completed Sucessfully!",
                timer:2000,
                timerProgressBar:true,
                didOpen:()=>{
                  Swal.showLoading();
                },
              }).then(() => {
                Swal.close();
                navigate("/request-token-transfer");
              });
            }
          );
        }
      }, 5000);
    },
  });

  const sendToken = async () => {
    Swal.fire({
      confirmButtonColor: "#d3bd0e",
      title: "Processing",
      html: "Please proceed your transaction through wallet!",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setWait(true);
    setIsChanged(true);
  };

  const chooseMethod = (choice) => {
    switch (choice) {
      case "tBNB":
        currentChain = "0x38";
        switchNetwork?.(97);
        break;
      case "GoerliETH":
        currentChain = "0x1";
        switchNetwork?.(5);
        break;
      case "BNB":
        currentChain = "0x38";
        switchNetwork?.(56);
        break;
      case "ETH":
        currentChain = "0x1";
        switchNetwork?.(1);
        break;
      default:
        break;
    }
  };

  const setPaymentMethod = async () => {
    AdminService.paymentTokens().then((response) => {
      setData(response.data.token);
    });
  };
  useEffect(() => {
    if (state?.payment_msg) {
      console.log(state?.payment_msg?.payment_method_types);
      if(state?.payment_msg?.payment_method_types == "card"){
        dispatch(saveStripePurchaseRecord(state?.payment_msg?.client_secret,state?.payment_msg?.payment_status,state?.payment_msg?.payment_method,((((state?.payment_msg?.amount)/100)-(admin?.data?.processingFee))/1.06),state?.payment_msg?.amount,admin?.data?.processingFee,(((((state?.payment_msg?.amount)/100)-admin?.data?.processingFee)/1.06)*0.06),state?.payment_msg?.paymentIntent_id));
      }else if(state?.payment_msg?.payment_method_types == "us_bank_account"){
        dispatch(saveStripePurchaseRecord(state?.payment_msg?.client_secret,state?.payment_msg?.payment_status,state?.payment_msg?.payment_method,(((state?.payment_msg?.amount)/100)-(admin?.data?.processingFee)),state?.payment_msg?.amount,admin?.data?.processingFee,0,state?.payment_msg?.paymentIntent_id));
      }
      
     
      if (state?.payment_msg?.status === "success") {
        toast.success(state.payment_msg.msg, {
          onClose: () =>  navigate("/request-token-transfer") });
        window.history.replaceState({}, document.title)
      }
      if (state?.payment_msg?.status === "info") {
        toast.info(state.payment_msg.msg);
        window.history.replaceState({}, document.title)
      }
      if (state?.payment_msg?.status === "error") {
        toast.error(state.payment_msg.msg);
        window.history.replaceState({}, document.title)
      }
    }
  },[state]);

  useEffect(() => {
    setPaymentMethod();
  },[]);

  useEffect(() => {
    console.log(stripeToken?.stripeData,state?.payment_msg?.amount);
    if(stripeToken?.stripeData?.data?.isSuccessful === true && currentUser.data?.referredBy !== "" && state?.payment_msg?.amount){
     UserService.saveReward((((state?.payment_msg?.amount/100)-(admin?.data?.processingFee))/1.06) ,stripeToken?.stripeData?.data?._id).then((res) => console.log("reward:",res))
   }
  
 }, [stripeToken]);

  useEffect(() => {
    if (admin.data) {
      // if(amount !== ""){
      //   setTotalAmount("Total amount is " + amount * admin?.data?.price  )
      // }
      if (amount === "") {
        setLimitError(`Required`);
      } else if (amount < admin.data.min) {
        setLimitError(`Minimum amount is $${admin.data.min.toFixed(2)}`);
      } else if (amount > admin.data.max) {
        setLimitError(`Maximum amount is $${admin.data.max.toFixed(2)}`);
      } else {
        setLimitError("");
      }
    }
  }, [amount]);

  useEffect(() => {
    if (currentItem?.companyWalletAddress && usdPrice && isChanged === true) {
      sendTransaction?.({
        recklesslySetUnpreparedRequest: {
          to: currentItem?.companyWalletAddress,
          value: BigInt(Math.floor(((Number(amount) + admin?.data?.processingFee)  / usdPrice) * 10 ** 18)),
          gasLimit: 21000,
        },
      });
    }
    if(isChanged == false){
      reset();
    }
  }, [isChanged]);

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container">
      <div className="form-width">
        <div  className="d-flex justify-content-between">
        <div className="color-lime align-middle mb-2"><p className="m-0">{admin?.data?.symbol} Price<span className="text-white"> : ${admin?.data?.price}</span></p></div>
        <div className="color-lime align-middle mb-2">Processing Fee<span className="text-white"> : ${admin?.data?.processingFee.toFixed(2)}</span></div>
        </div>
        <div className="">
          <input
            type="text"
            onKeyPress={(e) => Directives.NumberOnly(e)}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Amount to Purchase ($ USD)"
          />
        </div>
        <div>
          {wait && (
            <p className="text-danger">
              Transaction is already in progress. Please wait!
            </p>
          )}
          {limitError !== "" && <p className="text-danger">{limitError}</p>}
          {amount !== "" && limitError == "" && <p className="text-success">{"After processing fee. Total amount is $" +(Number(amount) + admin?.data?.processingFee).toFixed(2)} </p>}
        </div>

        <div className="button-width mt-3">
          <div>
            <a
              onClick={() => handleCreditCardModal("card")}
              className={`btn button-lime button-lime-hover text-white mb-3 ${
                wait ? "disabled" : " "
              }`}
            >
              Pay By Credit Card
            </a>
          </div>
          <div>
          <a
              onClick={() => handleCreditCardModal("us_bank_account")}
              className={`btn button-lime button-lime-hover text-white mb-3 ${
                wait ? "disabled" : " "
              }`}
            >
              Pay By ACH (Direct Bank)
            </a>
          </div>
          {data.map((item, i) => {
            return (
              item.isActive && (
                <div onClick={() => setCurrentItem(item)}>
                  <a
                    href="javascript:void(0)"
                    className={`btn button-lime button-lime-hover text-white mb-3 ${
                      wait ? "disabled" : " "
                    }`}
                    onClick={() => {
                      address
                        ? amount >= admin?.data?.min &&
                          amount <= admin?.data?.max
                          ? chooseMethod(item?.tokenName)
                          : setLimitError(
                              `Minimum amount should be ${admin?.data?.min} and less than ${admin?.data?.max}`
                            )
                        : open();
                    }}
                  >
                    {item?.buttonText}
                  </a>
                </div>
              )
            );
          })}
        </div>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default Purchase;
