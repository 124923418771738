import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;


const tokenConfig = (symbol) => {
    return axios
    .get(API_URL + "/admin/tokenconfig/" + symbol, { headers: authHeader() })
    .then((response) => {
        return response;
    });
}

const paymentTokens = () => {
    return axios
    .get(API_URL + "/user/payments/get/all" ,{headers: authHeader()})
    .then((response) => {
        return response;
    })
}


const AdminService = {
    tokenConfig,
    paymentTokens
}

export default AdminService;