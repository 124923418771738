import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import UserService from "../services/user.service";
import NoImage from "../assets/noImage.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Swal from "sweetalert2";
import { setAuth } from "../actions/auth";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [arr, setArr] = useState([]);
  const [notes, setNotes] = useState();
  const [showNote, setShowNote] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [linkCopy, setLinkCopy] = useState(false);
  const [editBody, setEditBody] = useState({
    firstName: currentUser?.data?.firstName || "",
    lastName: currentUser?.data?.lastName || "",
    phoneNumber: currentUser?.data?.phoneNumber || "",
    referredBy: currentUser?.data?.referredBy || "",
  });
  const [showPdf, setShowPdf] = useState(false);
  const [setPdf, setSelectPdf] = useState("");
  


  const closePdf = () => {
    setShowPdf(false);
  };

  const pdfModal = () => setShowPdf(true);

  const handlePdf = (e, data) => {
    setSelectPdf(data);
    e.preventDefault();
    pdfModal();
  };
  const dispatch = useDispatch();

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setShow(true);
  };

  const handleRowClick1 = (row) => {
    setSelectedRow(row);
    setShow1(true);
  };

  const handleRowClick2 = (row) => {
    setSelectedRow(row);
    setShowNote(true);
  };

  const closeModal = () => {
    setShow(false);
    setSelectedRow(null);
    setArr([]);
  };

  const closeModal1 = () => {
    setShow1(false);
    setSelectedRow(null);
    setArr([]);
  };

  const closeNote = () => {
    setShowNote(false);
    setSelectedRow(null);
  };

  const copyToClipboard = (address, i) => {
    navigator.clipboard.writeText(address);
    let array = [...arr];
    array.fill(false);
    array[i] = !array[i];
    setArr(array);
  };

  const copyTxToClipboard = (address, i) => {
    navigator.clipboard.writeText(address);
    let array = [...arr];
    array.fill(false);
    array[i] = !array[i];
    setArr(array);
  };

  const copyPurchaseToClipboard = (address, i) => {
    navigator.clipboard.writeText(address);
    let array = [...arr];
    array.fill(false);
    array[i] = !array[i];
    setArr(array);
  };

  const copyStripeToClipboard = (address, i) => {
    navigator.clipboard.writeText(address);
    let array = [...arr];
    array.fill(false);
    array[i] = !array[i];
    setArr(array);
  };

  
  

  const fixDecimals = (item) => {
    if (item) {
      return item.toFixed(6);
    }
  };

  const columns = [
    {
      name: "Purchase Date",
      selector: (row) => moment(Number(row.purchaseDate)).format("DD-MMM-YYYY HH:MM A"),
    },
    {
      name: "Purchase Status",
      selector: (row) => row.paymentStatus,
    },{
      name: "Transfer Status",
      selector: (row) => row.transferStatus,
    },
    // {
    //   name: "User Email",
    //   selector: (row) => row.userId?.email,
    // },
    {
      name: "USD",
      selector: (row) =>  "$" +row.amountUSD.toFixed(2),
    },
    {
      name: "BYOB",
      selector: (row) => row.byobAmount.toFixed(6),
    },
    {
      name: "Type",
      selector: (row) => row.purchaseType,
    },
    // {
    //   name: "Stripe ID",
    //   selector: (row) =>
    //     row.stripeId === "-"
    //       ? "-"
    //       : row.stripeId?.substring(0, 4) +
    //         "..." +
    //         row.stripeId?.substring(
    //           row.stripeId?.length - 4,
    //           row.stripeId?.length
    //         ),
    // },
    // {
    //   name: "Blockchain TxnId",
    //   //show first and last 4 characters with ...
    //   selector: (row) =>
    //     row.blockchainTxnId === "-"
    //       ? "-"
    //       : row.blockchainTxnId.substring(0, 4) +
    //         "..." +
    //         row.blockchainTxnId?.substring(
    //           row.blockchainTxnId?.length - 4,
    //           row.blockchainTxnId?.length
    //         ),
    // },
    // {
    //   name: "User Wallet Address",
    //   selector: (row) =>
    //     row.purchaseFromWallet?.slice(0, 6) +
    //     "...." +
    //     row.purchaseFromWallet?.slice(-5),
    // },
    
  ];

  const columns1 = [
    {
      name: "Request Date",
      selector: (row) =>
        row.transferRequestDate
          ? moment(Number(row.transferRequestDate)).format("DD-MMM-YYYY HH:MM A")
          : "-",
    },
    {
      name: "Transfer Date",
      selector: (row) =>
        row.transferDate
          ? moment(Number(row.transferDate)).format("DD-MMM-YYYY HH:MM A")
          : "-",
    },
    {
      name: "User Email",
      selector: (row) => row.userId?.email,
    },
    {
      name: "Amount in BYOB",
      selector: (row) => fixDecimals(row?.byobAmount),
    },

    {
      name: "Transfer Blockchain TxnId",
      selector: (row) =>
        row.transferBlockchainTxId
          ? row.transferBlockchainTxId.substring(0, 4) +
            "..." +
            row.transferBlockchainTxId.substring(
              row.transferBlockchainTxId.length - 4,
              row.transferBlockchainTxId.length
            )
          : "-",
    },
    {
      name: "Recepient Wallet",
      selector: (row) =>
        row.transferToWallet
          ? row.transferToWallet.slice(0, 5) +
            "...." +
            row.transferToWallet.slice(-5)
          : "-",
    },
    {
      name: "Transfer Status",
      selector: (row) => row.transferStatus,
    },
  ];

  const columns2 = [
    {
      name: "Created At",
      selector: (row) => moment(row.createdAt).format("DD-MMM-YYYY HH:MM A"),
      withd: "100px",
    },
    {
      name: "Description",
      selector: (row) =>
        row.description.slice(0, 8) + "...." + row.description.slice(-8),
      withd: "500px",
    },
    {
      name: "Image",
      cell: (row) => (
        <div className="img-table">
        {row.image?.split(".").pop() == "pdf" ? (
          <>  
        <button
        class="btn button-lime button-lime-hover btn-xs"
        onClick={(e) => handlePdf(e, row.image)}
      >
      <span>View Pdf file</span>
      </button>
                </>
              ) : (
                <img src={row.image || NoImage} alt="" />
              )}
      </div>
      ),
    },
  ];

  const columns3 = [
    {
      name: "First Name",
      selector: (row) => row.firstName,
      withd: "100px",
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      withd: "100px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      withd: "300px",
    },
    // {
    //   name: "Image",
    //   cell: (row) => (
    //     <div className="img-table">
    //       <img src={row.image || NoImage} alt="" />
    //     </div>
    //   ),
    // },
  ];

  const saveProfile = () =>
    UserService.saveProfile(
      editBody?.firstName,
      editBody?.lastName,
      editBody?.phoneNumber,
      editBody?.referredBy
    )
      .then((res) => {
        let c = JSON.parse(localStorage.getItem("user"));
        c.data.referredBy = editBody?.referredBy;
        c.data.firstName = editBody?.firstName;
        c.data.lastName = editBody?.lastName;
        c.data.phoneNumber = editBody?.phoneNumber;
        localStorage.setItem("user", JSON.stringify(c));
        dispatch(setAuth());
        setIsChange(false);
        Swal.fire({
          confirmButtonColor: "#d3bd0e",
          icon: "success",
          title: "Profile Saved",
          text: "Your profile has been saved successfully.",
        });
      })
      .catch((err) => {
        setIsChange(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: err.response.data.message || "Failed to Save Profile!.",
        });
      });

  useEffect(() => {
    UserService.getPurchases(currentUser?.data?._id).then((response) => {
      setData(response?.data?.data);
      setIsLoading(false);
    });

    UserService.getNotes(currentUser?.data?._id).then((response) => {
      setNotes(response.data?.data);
    });

    UserService.getReferralUsedBy().then((response) => {
      setReferrals(response.data.referralUsedBy);
    });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="container rounded bg-white">
          <div className="row">
            <div className="col-md-3 "></div>
            <div className="col border-right">
              <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 className="text-right">Profile Settings</h4>
                </div>
                <div className="col-md-12">
                  <label className="labels">Email ID</label>
                  <input
                    readOnly={true}
                    disabled={true}
                    className="form-control"
                    placeholder="enter email id"
                    value={currentUser?.data?.email}
                  />
                </div>
                <label className="labels">Referral Link</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={
                      window.location.origin +
                      "/register?referralCode=" +
                      currentUser?.data?.referralCode
                    }
                    readOnly
                  />
                  <div
                    className="ms-auto pointer copyIcon"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        window.location.origin +
                          "/register?referralCode=" +
                          currentUser?.data?.referralCode
                      );
                      setLinkCopy(true);
                    }}
                  >
                    <i className="fa-solid fa-copy"></i>
                  </div>
                  {linkCopy && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <label className="labels">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="first name"
                      value={editBody?.firstName}
                      onChange={(e) => {
                        setEditBody({ ...editBody, firstName: e.target.value });
                        setIsChange(true);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labels">Last Name</label>
                    <input
                      // type="text"
                      className="form-control"
                      value={editBody?.lastName}
                      placeholder="last name"
                      onChange={(e) => {
                        setEditBody({ ...editBody, lastName: e.target.value });
                        setIsChange(true);
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <label className="labels">Mobile Number</label>
                    <input
                      // type="text"
                      className="form-control"
                      //placeholder="enter phone number"
                      value={
                        editBody?.phoneNumber ? editBody?.phoneNumber : " "
                      }
                      onChange={(e) => {
                        setEditBody({
                          ...editBody,
                          phoneNumber: e.target.value,
                        });
                        setIsChange(true);
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="labels">Referred By</label>
                    <input
                      readOnly={currentUser?.data?.referredBy !== ""}
                      // type="text"
                      className="form-control"
                      placeholder="Enter Referral Email"
                      value={editBody?.referredBy}
                      onChange={(e) => {
                        setEditBody({
                          ...editBody,
                          referredBy: e.target.value,
                        });
                        setIsChange(true);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-5 text-center">
                  <button
                    className="btn btn-primary profile-button"
                    type="button"
                    disabled={!isChange}
                    onClick={() => saveProfile()}
                  >
                    Save Profile
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
          {/* <div className="container"> */}
          <Tabs>
            <TabList>
              <Tab>Purchases</Tab>
              <Tab>Transfers</Tab>
              <Tab>Notes From Admin</Tab>
              <Tab>My Referrals</Tab>
            </TabList>

            <TabPanel>
              <DataTable
                title="Purchases"
                columns={columns}
                data={data} //{data.filter((item) => item.transferStatus !== "completed")}
                onRowClicked={(row, event) => handleRowClick(row)}
                pagination
                highlightOnHover
                pointerOnHover
              />
            </TabPanel>
            <TabPanel>
              <DataTable
                title="Transfers"
                columns={columns1}
                data={data.filter(
                  (item) => (item.transferStatus === "completed" || item.transferStatus === "pending")
                )}
                onRowClicked={(row, event) => handleRowClick1(row)}
                pagination
                highlightOnHover
                pointerOnHover
              />
            </TabPanel>
            <TabPanel>
              <DataTable
                title="Notes By Admin"
                columns={columns2}
                data={notes}
                onRowClicked={(row, event) => handleRowClick2(row)}
                pagination
                highlightOnHover
                pointerOnHover
              />
            </TabPanel>
            <TabPanel>
              <DataTable
                title="Referral Used By"
                columns={columns3}
                data={referrals}
                // onRowClicked={(row, event) => handleRowClick2(row)}
                pagination
                highlightOnHover
                pointerOnHover
              />
            </TabPanel>
          </Tabs>
          {/* <h2 className="text-white py-4">Purchases</h2>
          <DataTable
            columns={columns}
            data= {data} //{data.filter((item) => item.transferStatus !== "completed")}
            onRowClicked={(row, event) => handleRowClick(row)}
            pagination
            highlightOnHover
            pointerOnHover
          /> */}
          {/* </div> */}
          {/* <div className="container my-3">
          <h2 className="text-white py-4">Transfers</h2>
          <DataTable
            columns={columns1}
            data={data.filter((item) => item.transferStatus === "completed")}
            onRowClicked={(row, event) => handleRowClick1(row)}
            pagination
            highlightOnHover
            pointerOnHover
          />
        </div> */}
          {/* <div className="container my-3">
          <h2 className="text-white py-4">Notes By Admin</h2>
          <DataTable
            columns={columns2}
            data={notes}
            onRowClicked={(row, event) => handleRowClick2(row)}
            pagination
            highlightOnHover
            pointerOnHover
          />
        </div> */}
        </div>

        <Modal show={show} onHide={closeModal} centered animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Purchase Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="mb-2">Email</label>
              <input
                type="text"
                className="form-control"
                value={selectedRow?.userId?.email}
                readOnly
              />
               <label className="mb-2">Purchase Status</label>
              <input
                type="text"
                className="form-control"
                value={selectedRow?.paymentStatus}
                readOnly
              />
              <label className="mb-2">User Wallet Address</label>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.purchaseFromWallet}
                  readOnly
                />
                {!arr[0] && (
                  <div
                    className="ms-auto pointer copyIcon"
                    onClick={() =>
                      copyToClipboard(selectedRow?.purchaseFromWallet, 0)
                    }
                  >
                    <i className="fa-solid fa-copy"></i>
                  </div>
                )}
                {arr[0] && (
                  <div className="ms-auto pointer text-primary copyIcon">
                    <small>Copied!</small>
                  </div>
                )}
              </div>
              <label className="mb-2">Transaction Type</label>
              <input
                type="text"
                className="form-control"
                value={selectedRow?.purchaseType}
                readOnly
              />
              <label className="mb-2">Purchase Date</label>
              <input
                type="text"
                className="form-control"
                value={moment(Number(selectedRow?.purchaseDate)).format(
                  "dddd, MMMM Do YYYY, h:mm:ss a"
                )}
                readOnly
              />
              <label className="mb-2">Amount in USD</label>
              <input
                type="text"
                className="form-control"
                value={"$"+selectedRow?.amountUSD}
                readOnly
              />
              {(selectedRow?.purchaseType === 'card') && (
               <label className="mb-2">Credit Card Fee</label>
             )}
             {(selectedRow?.purchaseType === 'card') && (
              <input
                type="text"
                className="form-control"
                value={"$"+selectedRow?.creditCardFeeUSD}
                readOnly
              />)}
               <label className="mb-2">processing Fee</label>
              <input
                type="text"
                className="form-control"
                value={"$"+selectedRow?.feeUSD}
                readOnly
              />
              <label className="mb-2">Amount in BYOB</label>
              <input
                type="text"
                className="form-control"
                value={fixDecimals(selectedRow?.byobAmount)}
                readOnly
              />
              <label className="mb-2">Transfer Status</label>
              <input
                type="text"
                className="form-control"
                value={selectedRow?.transferStatus}
                readOnly
              />
              <label className="mb-2">
              Blockchain Transaction ID
              </label>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedRow?.blockchainTxnId
                  }
                  readOnly
                />
                {!arr[1] && (
                  <div
                    className="ms-auto pointer copyIcon"
                    onClick={() =>
                      copyTxToClipboard(selectedRow?.blockchainTxnId, 1)
                    }
                  >
                    <i className="fa-solid fa-copy"></i>
                  </div>
                )}
                {arr[1] && (
                  <div className="ms-auto pointer text-primary copyIcon">
                    <small>Copied!</small>
                  </div>
                )}
              </div>
              <label className="mb-2">
              Stripe Transaction ID
              </label>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedRow?.stripeId?.substring(
                          0,
                          selectedRow?.stripeId?.indexOf("_secret_")
                        )
                      
                  }
                  readOnly
                />
                {!arr[1] && (
                  <div
                    className="ms-auto pointer copyIcon"
                    onClick={() =>
                    
                        copyStripeToClipboard(selectedRow?.stripeId?.substring(
                          0,
                          selectedRow?.stripeId?.indexOf("_secret_")
                        ), 1)
                       
                    }
                  >
                    <i className="fa-solid fa-copy"></i>
                  </div>
                )}
                {arr[1] && (
                  <div className="ms-auto pointer text-primary copyIcon">
                    <small>Copied!</small>
                  </div>
                )}
              </div>
              <label className="mb-2">
              Purchase Transaction ID
              </label>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  value={
                   selectedRow?.rewardPurchaseId
                  }
                  readOnly
                />
                {!arr[1] && (
                  <div
                    className="ms-auto pointer copyIcon"
                    onClick={() =>
                      copyPurchaseToClipboard(selectedRow?.rewardPurchaseId, 1)
                    }
                  >
                    <i className="fa-solid fa-copy"></i>
                  </div>
                )}
                {arr[1] && (
                  <div className="ms-auto pointer text-primary copyIcon">
                    <small>Copied!</small>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={show1}
          onHide={closeModal1}
          centered
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transfer Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mx-0 mb-4">
              <div className="col-md-6">
                <label className="mb-2">Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.userId?.email}
                  readOnly
                />
                <label className="mb-2">Amount in USD</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.amountUSD}
                  readOnly
                />
                <label className="mb-2">Amount in BYOB</label>
                <input
                  type="text"
                  className="form-control"
                  value={fixDecimals(selectedRow?.byobAmount)}
                  readOnly
                />
                <label className="mb-2">Transaction Type</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.purchaseType}
                  readOnly
                />
                <label className="mb-2">Request Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedRow?.transferRequestDate
                      ? moment(Number(selectedRow?.transferRequestDate)).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )
                      : "-"
                  }
                  readOnly
                />
                <label className="mb-2">Transfer Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedRow?.transferDate
                      ? moment(Number(selectedRow?.transferDate)).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )
                      : "-"
                  }
                  readOnly
                />
              </div>
              <div className="col-md-6">
                <label className="mb-2">Transfer Status</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.transferStatus}
                  readOnly
                />
                <label className="mb-2">Purchase Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={moment(Number(selectedRow?.purchaseDate)).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                  readOnly
                />
                <label className="mb-2">Recipient Wallet</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRow?.transferToWallet}
                    readOnly
                  />
                  {!arr[0] && (
                    <div
                      className="ms-auto pointer copyIcon"
                      onClick={() =>
                        copyToClipboard(selectedRow?.transferToWallet, 0)
                      }
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  )}
                  {arr[0] && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div>
                <label className="mb-2">Transfer Blockchain TxnId</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRow?.transferBlockchainTxId}
                    readOnly
                  />
                  {!arr[2] && (
                    <div
                      className="ms-auto pointer copyIcon"
                      onClick={() =>
                        copyToClipboard(selectedRow?.transferBlockchainTxId, 2)
                      }
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  )}
                  {arr[2] && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showNote} onHide={closeNote} centered animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Note by Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mx-0 mb-4">
              <b>
                <label className="mb-2">Description</label>
              </b>
              <textarea
                placeholder="Enter Description for transfer"
                value={selectedRow?.description}
                rows="10"
                readOnly
              ></textarea>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="modal-img">
                  <img src={selectedRow?.image || NoImage} alt="" />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal  dialogClassName="modal-xl modal-fullscreen-xl-down"show={showPdf} onHide={closePdf} centered animation={true}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        
                  <div>
                    <object data={setPdf} width="100%"  style={{ height: 'calc(100vh - 43px)' }} />
                  </div>
        </Modal.Body>
      </Modal>
      </>
    );
  }
};

export default Profile;
