import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useNavigate, useLocation } from "react-router-dom";

const Completion = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  let location = useLocation();
  let from = location.state?.from?.pathname || "/completion";
  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment

      switch (paymentIntent.status) {
        case "succeeded":
          setMessage({status:"success", msg:"Success! Payment received.", payment_status: paymentIntent.status, payment_method: paymentIntent.payment_method, client_secret: paymentIntent.client_secret, amount: paymentIntent.amount, paymentIntent_id: paymentIntent.id,payment_method_types:paymentIntent.payment_method_types[0] });
          break;

        case "processing":
          setMessage({status:"info", msg:"Payment processing. We'll update you when payment is received.", payment_status: paymentIntent.status, payment_method: paymentIntent.payment_method, client_secret: paymentIntent.client_secret, amount: paymentIntent.amount, paymentIntent_id: paymentIntent.id,payment_method_types:paymentIntent.payment_method_types[0]});
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage({status:"error", msg:"Payment failed. Please try another payment method.", payment_status: paymentIntent.status, payment_method: paymentIntent.payment_method, client_secret: paymentIntent.client_secret, amount: paymentIntent.amount, paymentIntent_id: paymentIntent.id,payment_method_types:paymentIntent.payment_method_types[0]});
          break;

        default:
          setMessage({status:"error", msg:"Something went wrong.", payment_status: paymentIntent.status, payment_method: paymentIntent.payment_method, client_secret: paymentIntent.client_secret, amount: paymentIntent.amount, paymentIntent_id: paymentIntent.id,payment_method_types:paymentIntent.payment_method_types[0]});
          break;
      }
    });
  }, [stripe]);
  if(message){
    navigate("/purchase",
    {state:  {payment_msg:message }});
  }
     
  //return message;
};

export default Completion;
