import {
  TOKEN_CONFIG,
  SET_TOKEN_CONFIG,
} from "../actions/type";

const initialState = { data: null };

export default function admin(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TOKEN_CONFIG:
      return {
        min: state.minPurchaseAmount,
        max: state.maxPurchaseAmount,
      };
    case SET_TOKEN_CONFIG:
      return {
        data: {
          ...state.data,
          min: payload.minPurchaseAmount,
          max: payload.maxPurchaseAmount,
          address: payload.tokenContractAddress,
          price : payload.tokenPrice,
          symbol : payload.symbol,
          processingFee: payload.processingFee,
          id: payload._id,
        },
      };
    default:
      return state;
  }
}
