import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
//import StripeService from '../services/stripe.service';
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { creditCardPayment } from "../actions/stripe";

function AchPayment(props) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [ clientSecret, setClientSecret ] = useState('');

  const { state } = useLocation();
  const dispatch = useDispatch();
  const tokenData = useSelector((state) => state.stripe.stripeToken);
 console.log(tokenData + "hj545ncvcbm232343");
  useEffect(() =>{
    setClientSecret(tokenData?.stripeToken?.data.stripeId);
  })
 
  useEffect(() => {
    console.log(state.user_amount,state.purchaseType);
   dispatch(creditCardPayment(state.user_amount,state.purchaseType));
      
  }, []);


  return (
    <>
      <h1 className="color-lime text-center">Payment</h1>
      {state.purchaseType == "card" && <p className="text-white text-center">{"$"+ (state.processingFee).toFixed(2) +" processing fee & 6% convenience fee."} </p>}
      {state.purchaseType == "card" && <p className="text-white text-center">{"Total amount is $" +(state.user_amount /100).toFixed(2) } </p>}
      {state.purchaseType == "us_bank_account" && <p className="text-white text-center">{"$"+ (state.processingFee).toFixed(2) +" processing fee. Total amount is  $" +(state.user_amount /100).toFixed(2) } </p>}

      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{clientSecret, }} key={clientSecret} >
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}

export default AchPayment;