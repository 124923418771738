export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const FORGOT_FAIL = "FORGOT_FAIL";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESEND_MAIL_SUCCESS = "RESEND_MAIL_SUCCESS";
export const RESEND_MAIL_FAIL = "RESEND_MAIL_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";


export const TOKEN_CONFIG = "TOKEN_CONFIG";
export const SET_TOKEN_CONFIG = "SET_TOKEN_CONFIG";
export const SET_ACTIVETOKEN_CONFIG = "SET_ACTIVETOKEN_CONFIG"
export const  CREDIT_PAYMENT_SUCCESS = "CREDIT_PAYMENT_SUCCESS";
export const CREDIT_PAYMENT_FAIL = "CREDIT_PAYMENT_FAIL";
export const  STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS";
export const STRIPE_PAYMENT_FAIL = "STRIPE_PAYMENT_FAIL";