import {
    SET_TOKEN_CONFIG
  } from "./type";
  
import AdminService from "../services/admin.service";

export const tokenConfig = (symbol) => (dispatch) => {
return AdminService.tokenConfig(symbol).then(
    (response) => {
    dispatch({
        type: SET_TOKEN_CONFIG,
        payload: response.data.data,
    });
    return Promise.resolve();
    },
    (error) => {  
    return Promise.reject();
    }
)};